import { useState } from 'react';
import PropTypes from 'prop-types';

import Download from 'components/Content/Variant/Download';

function VariantDisplay({ maxWidth, setLoading, variant }) {
  const [width, setWidth] = useState();

  const onImageLoad = ({ target }) => {
    setLoading(false);
    setWidth(target.width);
  };

  return (
    <>
      <img
        alt={variant.variantName}
        onLoad={onImageLoad}
        src={variant.url}
        style={maxWidth && width > maxWidth ? { width: maxWidth } : {}}
      />
      <Download variant={variant} />
    </>
  );
}

VariantDisplay.propTypes = {
  maxWidth: PropTypes.number,
  setLoading: PropTypes.func.isRequired,
  variant: PropTypes.shape({
    variantName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

VariantDisplay.defaultProps = {
  maxWidth: undefined,
};

export default VariantDisplay;
